import React, { useRef } from "react";
import CategoryCard from "../element/CategoryCard";
import BrowseJobToggler from "../element/BrowseJobToggler";
import JobCard from "../element/JobCard";
import MembershipCard from "../element/MembershipCard";
import NewFooter from "../element/NewFooter";
import { Link } from "react-router-dom";
import { useEffect, useState } from "react";
import axios from "axios";
import BaseApi from "../api/BaseApi";
import ApiKey from "../api/ApiKey";
import Marquee from "react-fast-marquee";
import Cookies from "js-cookie";
import Slider from "../element/Slider";
import { useTranslation } from "react-i18next";
import NewFeaturedJobsCard from "../element/NewFeaturedJobsCard";
// import NewNavbar from "../element/NewNavbar";
import Header from "../element/Header";
const UserPage = () => {
  const [homePageSloganTxt, setHomePageSloganTxt] = useState();
  const [homePageSloganTitle, setHomePageSloganTitle] = useState();
  const [categoryListing, setCategoryListing] = useState([]);
  const [jobCardData, setJobCardData] = useState([]);
  const [membershipData, setMembershipData] = useState([]);
  const [topEmployer, setTopEmployer] = useState([]);
  const [bannerDetails, setBannerDetails] = useState([]);
  const [loading, setLoading] = useState(false);
  const [keyword, setKeyword] = useState("");
  const [base64Code, setBase64Code] = useState();
  const [announcementDetails, setAnnouncementDetails] = useState([]);
  const [slider, setSlider] = useState([]);
  const [totalPageData, setTotalPageData] = useState([]);
  const [t, i18n] = useTranslation("global");

  const tokenKey = Cookies.get("tokenClient");

  let primaryColor = Cookies.get("primaryColor");
  let secondaryColor = Cookies.get("secondaryColor");

  const [hoverSearchColor, setHoverSearchColor] = useState(false);

  const handleSearchMouseEnter = () => {
    setHoverSearchColor(true);
  };

  const handleSearchMouseLeave = () => {
    setHoverSearchColor(false);
  };

  const [hoverUploadCVColor, setHoverUploadCVColor] = useState(false);

  const handleUploadCVMouseEnter = () => {
    setHoverUploadCVColor(true);
  };

  const handleUploadCVMouseLeave = () => {
    setHoverUploadCVColor(false);
  };

  // Explore all Categories button
  const [hoverExploreCategoriesColor, setHoverExploreCategoriesColor] =
    useState(false);

  const handleExploreCategoriesEnter = () => {
    setHoverExploreCategoriesColor(true);
  };

  const handleExploreCategoriesLeave = () => {
    setHoverExploreCategoriesColor(false);
  };

  // View Job Button
  const [hoverViewJobColor, setHoverViewJobColor] = useState(false);

  const handleViewJobEnter = () => {
    setHoverViewJobColor(true);
  };

  const handleViewJobLeave = () => {
    setHoverViewJobColor(false);
  };

  const getData = async () => {
    try {
      setLoading(true);
      const response = await axios.get(BaseApi + `/home`, {
        headers: {
          "Content-Type": "application/json",
          key: ApiKey,
          token: tokenKey,
        },
      });
      setLoading(false);
      setHomePageSloganTxt(response.data.response.site_setting.slogan_text);
      setHomePageSloganTitle(response.data.response.site_setting.slogan_title);
      setCategoryListing(response.data.response.categories_listing);
      setJobCardData(response.data.response.latestJobList);
      setMembershipData(response.data.response.plans_details);
      setTopEmployer(response.data.response.top_employer);
      setBannerDetails(response.data.response.bannerDetails);
      setAnnouncementDetails(response.data.response.announcementList);
      setSlider(response.data.response.sliderList);
      setTotalPageData(response.data.response);

      Cookies.set("fbLink", response.data.response.site_setting.facebook_link);
      Cookies.set(
        "instaLink",
        response.data.response.site_setting.instagram_link
      );
      Cookies.set(
        "linkedInLink",
        response.data.response.site_setting.linkedin_link
      );
      Cookies.set(
        "pinterestLink",
        response.data.response.site_setting.pinterest
      );
      Cookies.set(
        "twitterLink",
        response.data.response.site_setting.twitter_link
      );
      // console.log(jobCardData);
    } catch (error) {
      setLoading(false);
      console.log("Cannot get home page data!");
    }
  };

  const [selectedFile, setSelectedFile] = useState(null);
  const fileInputRef = useRef(null);

  const handleFileUpload = () => {
    fileInputRef.current.click();
  };

  const handleFileChange = (e) => {
    const file = e.target.files[0];

    // Check if the selected file is a .doc file
    if (file) {
      if (file.type === "application/msword" || file.name.endsWith(".doc")) {
        // Read the file using FileReader to get Base64 data
        const reader = new FileReader();
        reader.onloadend = () => {
          const base64Data = reader.result;
          setSelectedFile(file);
          setBase64Code(base64Data); // Assuming you have a state variable 'base64Code' to store the Base64 data
        };
        reader.readAsDataURL(file);
      } else {
        // Display an error message for file types other than .doc
        alert("Please select a .doc file (Microsoft Word document).");
      }
    }
  };
  console.log(base64Code);

  useEffect(() => {
    getData();
  }, []);

  return (
    <>
      <Header />
      {loading ? (
        <>
          <div className="loader-container"></div>
        </>
      ) : (
        <>
          <div>
            {/* First block of the home page */}

            <div className="sliderbody">
              {slider.length > 0 ? (
                <>
                  <div className="">
                    <Slider />
                    <div className="upperSlider">
                      <div className="container">
                        <div className="row">
                          <div className="col-md-10 col-lg-12">
                            <div className="upperSectionBox">
                              <div className="slidertext">
                                <h1>{homePageSloganTitle}</h1>
                                {/* <p>{homePageSloganTxt}</p> */}
                              </div>
                              <div className="searcharea">
                                <i>
                                  <img src="/Images/searchicon.png" alt="" />
                                </i>
                                <div className="inputGrp">
                                  <input
                                    type="search"
                                    className="form-control"
                                    placeholder={t("userpage.jobTitleKeyword")}
                                    aria-label="Search"
                                    aria-describedby="search-addon"
                                    value={keyword}
                                    name="keyword"
                                    onChange={(e) => {
                                      setKeyword(e.target.value);
                                    }}
                                  />
                                </div>
                                {keyword ? (
                                  <Link
                                    to={`/searchjob/${keyword}`}
                                    type="button"
                                    className="btn btn-primary button1"
                                    style={{
                                      backgroundColor: hoverSearchColor
                                        ? secondaryColor
                                        : primaryColor,
                                      border: hoverSearchColor
                                        ? secondaryColor
                                        : primaryColor,
                                    }}
                                    onMouseEnter={handleSearchMouseEnter}
                                    onMouseLeave={handleSearchMouseLeave}
                                  >
                                    {t("userpage.searchButton")}
                                  </Link>
                                ) : (
                                  <Link
                                    to="/searchjob"
                                    type="button"
                                    className="btn btn-primary button1"
                                    style={{
                                      backgroundColor: hoverSearchColor
                                        ? secondaryColor
                                        : primaryColor,
                                      border: hoverSearchColor
                                        ? secondaryColor
                                        : primaryColor,
                                    }}
                                    onMouseEnter={handleSearchMouseEnter}
                                    onMouseLeave={handleSearchMouseLeave}
                                  >
                                    {t("userpage.searchButton")}
                                  </Link>
                                )}

                                {/* <button
                                  type="button"
                                  onClick={handleFileUpload}
                                  className="btn btn-primary uploadCvButton"
                                  style={{
                                    color: hoverUploadCVColor
                                      ? primaryColor
                                      : secondaryColor,
                                    backgroundColor: "white",
                                    border: hoverUploadCVColor
                                      ? `2px solid ${primaryColor}`
                                      : `2px solid ${secondaryColor}`,
                                  }}
                                  onMouseEnter={handleUploadCVMouseEnter}
                                  onMouseLeave={handleUploadCVMouseLeave}
                                >
                                  {t("userpage.uploadCVButton")}
                                </button> */}
                                {/* <input
                                  type="file"
                                  ref={fileInputRef}
                                  accept=".doc"
                                  style={{ display: "none" }}
                                  onChange={handleFileChange}
                                /> */}
                              </div>
                              {selectedFile && (
                                <>
                                  <div className="mt-3 d-flex">
                                    <p className="pt-2">
                                      Selected File: {selectedFile.name}
                                    </p>
                                    {/* You can use the selectedFile here or show additional file information */}
                                    <button
                                      className="btn btn-outline-dark ms-2"
                                      onClick={() => setSelectedFile()}
                                    >
                                      {t("userpage.deleteButton")}
                                    </button>
                                  </div>
                                </>
                              )}
                              {/* <div className="slidertext2">
                                <div className="jobs-clients-posted">
                                  <h3>{totalPageData.jobscount}</h3>
                                  <h6 className="text-white">
                                    {t("userpage.jobsPosted")}
                                  </h6>
                                </div>
                                <div className="jobs-clients-posted">
                                  <h3>{totalPageData.freelancers}</h3>
                                  <h6 className="text-white">
                                    {t("userpage.employers")}
                                  </h6>
                                </div>
                              </div> */}
                            </div>
                          </div>
                        </div>
                      </div>
                    </div>
                    <div className="globalImage">
                      <img
                        src="/Images/globe-awards-winner.png"
                        target="_blank"
                      />
                    </div>
                  </div>
                </>
              ) : (
                <>
                  <div className="lowerSlider">
                    <div className="container container">
                      <div className="row">
                        <div className="col-md-10 col-lg-8">
                          <div className="slidertext">
                            <h1>{homePageSloganTitle}</h1>
                            <p>{homePageSloganTxt}</p>
                          </div>
                          <div className="searcharea">
                            <i>
                              <img src="/Images/searchicon.png" alt="" />
                            </i>
                            <div className="inputGrp">
                              <input
                                type="search"
                                className="form-control"
                                placeholder="Job title or keyword"
                                aria-label="Search"
                                aria-describedby="search-addon"
                                value={keyword}
                                name="keyword"
                                onChange={(e) => {
                                  setKeyword(e.target.value);
                                }}
                              />
                            </div>
                            {keyword ? (
                              <Link
                                to={`/searchjob/${keyword}`}
                                type="button"
                                className="btn btn-primary button1"
                                style={{
                                  backgroundColor: hoverSearchColor
                                    ? secondaryColor
                                    : primaryColor,
                                  border: hoverSearchColor
                                    ? secondaryColor
                                    : primaryColor,
                                }}
                                onMouseEnter={handleSearchMouseEnter}
                                onMouseLeave={handleSearchMouseLeave}
                              >
                                {t("userpage.searchButton")}
                              </Link>
                            ) : (
                              <Link
                                to="/searchjob"
                                type="button"
                                className="btn btn-primary button1"
                                style={{
                                  backgroundColor: hoverSearchColor
                                    ? secondaryColor
                                    : primaryColor,
                                  border: hoverSearchColor
                                    ? secondaryColor
                                    : primaryColor,
                                }}
                                onMouseEnter={handleSearchMouseEnter}
                                onMouseLeave={handleSearchMouseLeave}
                              >
                                {t("userpage.searchButton")}
                              </Link>
                            )}

                            <button
                              type="button"
                              onClick={handleFileUpload}
                              className="btn btn-primary uploadCvButton"
                              style={{
                                color: hoverUploadCVColor
                                  ? primaryColor
                                  : secondaryColor,
                                backgroundColor: "white",
                                border: hoverUploadCVColor
                                  ? `2px solid ${primaryColor}`
                                  : `2px solid ${secondaryColor}`,
                              }}
                              onMouseEnter={handleUploadCVMouseEnter}
                              onMouseLeave={handleUploadCVMouseLeave}
                            >
                              {t("userpage.uploadCVButton")}
                            </button>
                            <input
                              type="file"
                              ref={fileInputRef}
                              accept=".doc"
                              style={{ display: "none" }}
                              onChange={handleFileChange}
                            />
                          </div>
                          {selectedFile && (
                            <>
                              <div className="mt-3 d-flex">
                                <p className="pt-2">
                                  Selected File: {selectedFile.name}
                                </p>
                                {/* You can use the selectedFile here or show additional file information */}
                                <button
                                  className="btn btn-outline-dark ms-2"
                                  onClick={() => setSelectedFile()}
                                >
                                  {t("userpage.deleteButton")}
                                </button>
                              </div>
                            </>
                          )}
                          <div className="slidertext2">
                            <div className="jobs-clients-posted">
                              <h3>{totalPageData.jobscount}</h3>
                              <h6 className="text-muted">
                                {t("userpage.jobsPosted")}
                              </h6>
                            </div>
                            <div className="jobs-clients-posted">
                              <h3>{totalPageData.freelancers}</h3>
                              <h6 className="text-muted">
                                {t("userpage.employers")}
                              </h6>
                            </div>
                          </div>
                        </div>
                        <div className="BannerImg">
                          <img
                            className="layer"
                            data-speed="-20"
                            src="/Images/banner-img.png"
                            alt=""
                          />
                          <div className="BannerImg1 layer" data-speed="-8">
                            <img src="/Images/banner-userimg1.png" alt="" />
                          </div>
                          <div className="BannerImg3 layer" data-speed="8">
                            <img src="/Images/bannerimg1.png" alt="" />
                          </div>
                          <div className="BannerImg4 layer" data-speed="10">
                            <img src="/Images/bannerimg2.png" alt="" />
                          </div>
                          <div className="BannerImg2 layer" data-speed="-5">
                            <img src="/Images/banner-userimg2.png" alt="" />
                          </div>
                        </div>
                      </div>
                    </div>
                  </div>
                </>
              )}
            </div>

            <section className="intro">
              <div className="container">
                <div className="row">
                  <div className="col-xs-12 d-lg-none d-block text-center">
                    {/* <Link to="/Images/globe-awards-winner.png" target="_blank"></Link> */}
                    <img
                      src="/Images/globe-awards-winner.png"
                      target="_blank"
                    />
                  </div>
                  <div className="col-md-12 text-center">
                    <h2>
                      Australia’s national LGBTI job board dedicated to
                      assisting all graduates and jobseekers connect with
                      organisations actively engaged in LGBTI inclusion
                      initiatives.
                    </h2>
                    <h3>
                      We encourage people from all walks of life to apply for
                      the advertised roles. You may not identify as part of the
                      rainbow family but simply want to work in a more inclusive
                      and understanding work culture.
                    </h3>
                    <p>
                      We also would like to recognise there are many beautiful
                      colours to the rainbow (LGBTTQQIAAP+...) but we simply had
                      to shorten it for our logo and ease of use, generally.
                    </p>
                  </div>
                </div>
              </div>
            </section>
            <section className="featured-jobs">
              <div className="container">
                <div className="row">
                  <div className="col-md-12">
                    <h1>
                      <span class="title-wrap">Featured jobs</span>
                    </h1>
                  </div>
                </div>
                <div className="row">
                  {jobCardData.slice(0, 6).map((i) => {
                    return (
                      <>
                        <NewFeaturedJobsCard
                          title={i.title}
                          min_salary={i.min_salary}
                          max_salary={i.max_salary}
                          min_exp={i.min_exp}
                          created={i.created}
                          logo={i.logo}
                          company_name={i.company_name}
                          work_type={i.work_type}
                          job_city={i.job_city}
                          slug={i.slug}
                          cat_slug={i.cat_slug}
                          // desc={i.brief_abtcomp}
                        />
                      </>
                    );
                  })}
                </div>
                <div className="row">
                  <div className="col-md-12 text-center">
                    <Link
                      to="/user/register/jobseeker"
                      className="btn btn-lg btn-register"
                    >
                      <i
                        class="fa fa-check-square-o"
                        style={{ marginRight: "10px" }}
                      ></i>
                      REGISTER ONLINE
                    </Link>
                    <Link
                      to="/searchjob"
                      className="btn btn-lg btn-search-jobs"
                    >
                      <i
                        class="fa fa-search"
                        style={{ marginRight: "10px" }}
                      ></i>
                      SEARCH JOBS
                    </Link>
                  </div>
                </div>
              </div>
            </section>

            <section className="why-lgbti-jobs">
              <div className="container">
                <div className="row">
                  <div className="col-md-12">
                    <h1>
                      <span class="title-wrap">
                        Why <span class="alt-color-1">lgbti</span>{" "}
                        <span class="alt-color-2">jobs</span> ?
                      </span>
                    </h1>
                    <h2>
                      LGBTI Jobs was created by a Leadership Team and Advisory
                      Board who are heavily involved in the LGBTI movement. Our
                      unique collaboration of experience and knowledge makes
                      LGBTI Jobs well placed to cater for a community that often
                      faces massive discrimination. We assist job seekers and
                      organisations in a very real and practical way by creating
                      a necessary and effective platform to unite the two.
                    </h2>
                  </div>
                </div>
                <div className="row">
                  <div className="col-md-6 pb-5">
                    <h3>Employers</h3>
                    <h4>
                      <i class="fa fa-support"></i>
                      CONNECT
                    </h4>
                    <p>
                      Connect with the LGBTI community and inform potential
                      candidates about your diversity and inclusion initiatives.
                      Increase the number of applications from people who
                      identify as LGBTI+, or who desire to work in a more
                      inclusive workplace.
                    </p>

                    <h4>
                      <i class="fa fa-heart"></i>
                      BRANDING
                    </h4>
                    <p>
                      Our experienced web development and design team are ready
                      to integrate your brand via microsites and individual case
                      studies to help raise your company profile as a diversity
                      and inclusion employer of choice.
                    </p>

                    <h4>
                      <i class="fa fa-comments"></i>
                      EDUCATION
                    </h4>
                    <p>
                      LGBTI Jobs are ready to assist your business in educating
                      employees to ensure a truly inclusive workplace
                      environment and culture. This might be delivering a
                      tailored training program to C-level staff through to
                      factory floor workers.
                    </p>
                  </div>

                  <div className="col-md-6 pb-5">
                    <h3>JOB SEEKERS</h3>
                    <h4>
                      <i>
                        <img src="/Images/checked_icon.png" alt="" />
                      </i>
                      Pre-Screened Businesses
                    </h4>
                    <p>
                      All companies advertising on LGBTI Jobs are obliged to
                      pass our LGBTI Inclusivity Compliance Assessment to ensure
                      they have a truly inclusive culture with relevant policies
                      in place to provide a completely safe workplace.
                    </p>
                    <p className="my-3">
                      We take this seriously and conduct in-depth discussions
                      with all our advertisers so they can walk us through their
                      processes and we even provide in-house training.
                    </p>

                    <h4>
                      <i class="fa fa-thumbs-up"></i>
                      AN INDEPENDENT VOICE
                    </h4>
                    <p>
                      LGBTI Jobs guarantees no bias or spin. We look past the
                      'pinkwashing' to ensure that every company advertising on
                      our site provides a safe work place for everyone, and that
                      isn’t just about policy; it’s about ensuring a truly
                      inclusive workplace culture.
                    </p>
                  </div>
                </div>
              </div>
            </section>

            <section className="services-lgbti">
              <div className="container">
                <div className="row">
                  <div className="col-md-12">
                    <h1>
                      <span class="title-wrap">Services</span>
                    </h1>
                  </div>
                </div>

                <div className="row">
                  <div className="col-md-6 pb-5">
                    <div className="text-wrap">
                      <h3>INCLUSIVITY & COMPLIANCE ASSESSMENT</h3>
                      <p>
                        This assessment brings together the findings of our
                        experience helping employers embed LGBTI diversity and
                        inclusion throughout their organisations.
                      </p>
                      <p>
                        Feedback - Designed to match the opportunities, concerns
                        and issues raised by lesbian, gay and bisexual
                        respondents with appropriate actions on behalf of
                        employers and colleagues.
                      </p>
                      <ul>
                        <li>
                          <img
                            src="/Images/checked_icon.png"
                            alt=""
                            style={{ width: "20px", marginRight: "10px" }}
                          />
                          Developed in consultation with Maddocks Lawyers
                        </li>
                        <li>
                          <img
                            src="/Images/checked_icon.png"
                            alt=""
                            style={{ width: "20px", marginRight: "10px" }}
                          />
                          We work with you to provide tailored training to suit
                          your needs
                        </li>
                        <li>
                          <img
                            src="/Images/checked_icon.png"
                            alt=""
                            style={{ width: "20px", marginRight: "10px" }}
                          />
                          Happy to sign non-disclosure or confidentiality
                          agreements
                        </li>
                      </ul>
                    </div>
                  </div>

                  <div className="col-md-6 pb-4">
                    <div className="text-wrap">
                      <h3>ON-SITE TRAINING</h3>
                      <h2>
                        Sessions are designed for 10-15 people – any more may
                        require an additional session.
                      </h2>
                      <h4>Topics</h4>
                      <ul>
                        <li>
                          <img
                            src="/Images/checked_icon.png"
                            alt=""
                            style={{ width: "20px", marginRight: "10px" }}
                          />
                          Gender & Sexuality
                        </li>
                        <li>
                          <img
                            src="/Images/checked_icon.png"
                            alt=""
                            style={{ width: "20px", marginRight: "10px" }}
                          />
                          LGBTI Leadership
                        </li>
                        <li>
                          <img
                            src="/Images/checked_icon.png"
                            alt=""
                            style={{ width: "20px", marginRight: "10px" }}
                          />
                          LGBTI Allies
                        </li>
                        <li>
                          <img
                            src="/Images/checked_icon.png"
                            alt=""
                            style={{ width: "20px", marginRight: "10px" }}
                          />
                          Transgender transition in the workplace
                        </li>
                        <li>
                          <img
                            src="/Images/checked_icon.png"
                            alt=""
                            style={{ width: "20px", marginRight: "10px" }}
                          />
                          Workplace behaviour relating to transphobia and
                          homophobia
                        </li>
                      </ul>
                    </div>
                  </div>
                </div>

                <div className="row">
                  <div className="col-md-12 text-center">
                    <p>
                      <Link to="/contact" className="btn btn-primary">
                        <i
                          class="fa fa-search"
                          style={{ marginRight: "10px" }}
                        ></i>
                        CONTACT US FOR MORE INFO
                      </Link>
                    </p>
                  </div>
                </div>
              </div>
            </section>

            <section className="featuredEmployer">
              <div className="container">
                <div className="row">
                  <div className="col-md-12">
                    <h1>
                      <span class="title-wrap">
                        FEATURED
                        <br />
                        EMPLOYERS
                      </span>
                    </h1>
                    <h2>FULLY SCREENED AND APPROVED</h2>

                    <p>
                      {/* <div className="row m-0"> */}
                      <ul className="topEmpNew">
                        {topEmployer.map((i) => {
                          return (
                            <>
                              {/* <div className="col-xs-12 col-md-3 col-lg-3 p-0 "> */}
                              <li>
                                <div className="EmployersLogoBx ">
                                  <Link to={`/companyprofile/${i.slug}`}>
                                    <img
                                      className="employersLogo"
                                      src={i.profile_image}
                                      alt=""
                                    />
                                  </Link>
                                </div>
                              </li>
                              {/* </div> */}
                            </>
                          );
                        })}
                        {/* </div> */}
                      </ul>
                    </p>
                  </div>
                </div>
              </div>
            </section>

            {/* Second block of the home page */}
            {/* <section className="CategorySection">
              <div className="container">
              
                <div className="row">
                  <div className="col-xs-12 col-md-4 col-lg-4">
                    <Link to="/user/register/jobseeker">
                      <div className="CategoryBx">
                        <img src="/Images/CreateAnAccount.jpg" alt="" />
                        <h5 className="">{t("userpage.createAnAcount")}</h5>
                        <p>
                        {t("userpage.belowTxt1")}
                        </p>
                      </div>
                    </Link>
                  </div>
                  <div className="col-xs-12 col-md-4 col-lg-4">
                  <Link to="/searchjob">
                    <div className="CategoryBx">
                      <img src="/Images/SearchDesiredJob.jpg" alt="" />
                      <h5 className="">{t("userpage.searchDesiredJob")}</h5>
                      <p>
                      {t("userpage.belowTxt2")}
                      </p>
                    </div>
                    </Link>
                  </div>
                  <div className="col-xs-12 col-md-4 col-lg-4">
                  <Link to="/user/jobseekerlogin">
                    <div className="CategoryBx">
                      <img src="/Images/SendYourResume.jpg" alt="" />
                      <h5 className="">{t("userpage.sendResume")}</h5>
                      <p>
                      {t("userpage.belowTxt3")}
                      </p>
                    </div>
                    </Link>
                  </div>
                </div>
                </div>
            </section> */}

            {/* third block of the home page */}

            {/* Fourth block of the home page */}

            {/* Fifth block of the home page */}

            {/* {topEmployer != "" && (
              <>
                <section className="TopEmployersSection">
                  <div className="container text-center">
                    <h3 className="subsecondblockHeading">
                      {t("userpage.top")}
                      <span className="textGradient">
                        {" "}
                        <span className="SubHaddingTxt">
                          {t("userpage.employer")}
                        </span>{" "}
                      </span>
                    </h3>
                    <div className="TopEmployersBx">
                      <div className="row m-0">
                        {topEmployer.map((i) => {
                          return (
                            <>
                              <div className="col-xs-12 col-md-3 col-lg-3 p-0 TopEmployersBxCardBx">
                                <div className="EmployersLogoBx BorderRight BorderBottom">
                                  <Link to={`/companyprofile/${i.slug}`}>
                                    <img
                                      className="employersLogo"
                                      src={i.profile_image}
                                      alt=""
                                    />
                                  </Link>
                                </div>
                              </div>
                            </>
                          );
                        })}
                      </div>
                    </div>
                  </div>
                </section>
              </>
            )} */}

            {/* Sixth block of the home page */}

            {/* <div className="sixthBlock">
              <section className="DownloadAppSection">
                <div className="container">
                  <div className="row">
                    <div className="col-xs-12 col-md-6 col-lg-6">
                      <div className="section1text ">
                        <h2 className="">{t("userpage.downloadApp")}</h2>
                        <h3>{t("userpage.belowTxt4")}</h3>

                        <div className="section1button">
                          <Link to="" target="_blank">
                            <img
                              className=""
                              src="/Images/googlePlayButtton.png"
                              alt=""
                            />
                          </Link>
                          <Link to="" target="_blank">
                            <img
                              className=""
                              src="/Images/appleStoreButton.png"
                              alt=""
                            />
                          </Link>
                        </div>
                      </div>
                    </div>
                    <div className="col-xs-12 col-md-6 col-lg-6">
                      <div className="DownloadRightImg">
                        <img
                          className="phoneImage"
                          src="/Images/Phone.png"
                          alt=""
                        />
                        <img
                          className="girlImage"
                          src="/Images/girl.png"
                          alt=""
                        />
                      </div>
                    </div>
                  </div>
                </div>
              </section>
              {bannerDetails != "" && (
                <>
                  <div className="section2">
                    <div className="row">
                      {bannerDetails.map((i) => {
                        return (
                          <>
                            <div className="col-md-6 col-sm-12">
                              <Link to={i.url}>
                                <img
                                  className="flexImage"
                                  src={i.image}
                                  alt=""
                                />
                              </Link>
                            </div>
                          </>
                        );
                      })}
                    </div>
                  </div>
                </>
              )}
            </div> */}
          </div>
          {/* <Marquee
            className="marqueeText"
            pauseOnHover={true}
            style={{
              backgroundColor: primaryColor,
            }}
          >
            {announcementDetails.map((i) => {
              return (
                <Link to={i.url} className="marqueeLink" target="_blank">
                  | {i.name + " "} |
                </Link>
              );
            })}
          </Marquee> */}
          {/* <div className="pt-3">
            <div className="container">
              <div className="col-md-12 text-center bottom-lines">
                <img
                  src="/Images/footer-lines.png"
                  className="img-responsive d-inline-block"
                />
              </div>
            </div>
          </div> */}
         
          <NewFooter />
         
          
        </>
      )}
    </>
  );
};

export default UserPage;
document.addEventListener("mousemove", parallax);

function parallax(e) {
  this.querySelectorAll(".layer").forEach((layer) => {
    let speed = layer.getAttribute("data-speed");
    let x = (window.innerWidth - e.pageX * speed) / 900;
    let y = (window.innerWidth - e.pageY * speed) / 900;
    layer.style.transform = `translate(${x}px, ${y}px)`;
  });
}
