import React, { useEffect, useState } from "react";
import { Link } from "react-router-dom";
import Cookies from "js-cookie";
import { useTranslation } from "react-i18next";

const Footer = () => {
  const [isQuickLinksVisible, setIsQuickLinksVisible] = useState(false);
  const [isAboutusLinksVisible, setIsAboutusLinksVisible] = useState(false);
  const [isJobseekerLinksVisible, setIsJobseekerLinksVisible] = useState(false);

  let siteTitle = Cookies.get("siteTitle");
  let siteLink = Cookies.get("siteLink");
  let faceboookLink = Cookies.get("fbLink");
  let instagramLink = Cookies.get("instaLink");
  let twitterLink = Cookies.get("twitterLink");
  let pinterestLink = Cookies.get("pinterestLink");
  let linkedInLink = Cookies.get("linkedInLink");
  const [t, i18n] = useTranslation("global");

  const [selectedLanguage, setSelectedLanguage] = useState(
    Cookies.get("selectedLanguage") || "en"
  );

  const currentLanguage = Cookies.get("selectedLanguage") || "";

  const handleChangeLanguage = (selectedValue) => {
    if (selectedValue) {
      i18n.changeLanguage(selectedValue);
      window.scrollTo(0, 0);
    } else {
      i18n.changeLanguage(currentLanguage);
      window.scrollTo(0, 0);
    }
    window.location.reload();
    setSelectedLanguage(selectedValue);
    Cookies.set("selectedLanguage", selectedValue, { expires: 365 });
  };
  useEffect(() => {
    if (currentLanguage) {
      i18n.changeLanguage(currentLanguage);
      window.scrollTo(0, 0);
    }
  }, []);

  // const [footerName, setFooterName] = useState();
  // const [footerLink, setFooterLink] = useState();

  // const getData = async () => {
  //   try {
  //     const response = await axios.get(BaseApi + "/getconstant");
  //     setFooterLink(response.data.response.site_link);
  //     setFooterName(response.data.response.site_title);
  //   } catch (error) {
  //     console.log("Error getting navbar logo information!");
  //   }
  // };
  useEffect(() => {
    // getData();
    // window.scrollTo(0, 0);
  }, []);

  const toggle3 = () => {
    setIsQuickLinksVisible(!isQuickLinksVisible);
  };
  const toggle2 = () => {
    setIsAboutusLinksVisible(!isAboutusLinksVisible);
  };
  const toggle1 = () => {
    setIsJobseekerLinksVisible(!isJobseekerLinksVisible);
  };

  useEffect(() => {
    const handleWindowResize = () => {
      if (window.innerWidth < 768) {
        setIsQuickLinksVisible(false);
        setIsAboutusLinksVisible(false);
        setIsJobseekerLinksVisible(false);
      } else {
        setIsQuickLinksVisible(false);
        setIsAboutusLinksVisible(false);
        setIsJobseekerLinksVisible(true);
      }
    };

    // Call the function on component mount
    handleWindowResize();

    // Attach event listener for window resize
    window.addEventListener("resize", handleWindowResize);

    // Clean up event listener on component unmount
    return () => {
      window.removeEventListener("resize", handleWindowResize);
    };
    // getData();
  }, []);

  let screenWidth = window.innerWidth;

  return (
    <>
      {screenWidth > 768 ? (
        <>
          <footer>
            <div className="container">
              <div className="">
                <div className="col-md-12 text-center bottom-lines">
                  <img
                    src="/Images/footer-lines.png"
                    className="img-responsive d-inline-block"
                  />
                </div>
              </div>
              <div className="row">
                <div className="col-md-8">
                  <ul className="nav footer-nav">
                    {/* <li>
                      <Link to="">Home</Link>
                    </li> */}
                    <li>
                      <Link to="/jobseeker-registration-information">
                        JOB SEEKERS
                      </Link>
                    </li>

                    {/* <li class="nav-item dropdown">
                      <Link
                        className="nav-link dropdown-toggle"
                        to=""
                        role="button"
                        data-bs-toggle="dropdown"
                        aria-expanded="false"
                      >
                        Job Seekers <span class="caret"></span>
                      </Link>
                      <ul class="dropdown-menu">
                        <li>
                          <Link className="dropdown-item" to="/newsearchjob">
                            Search jobs
                          </Link>
                        </li>
                        <li>
                          <Link
                            className="dropdown-item"
                            to="/user/jobseekerlogin"
                          >
                            Jobseeker login
                          </Link>
                        </li>
                        <li>
                          <Link className="dropdown-item" to="/alerts/add">
                            Create job alert
                          </Link>
                        </li>
                        <li>
                          <Link
                            className="dropdown-item"
                            to="/candidates/editExperience"
                          >
                            Experience
                          </Link>
                        </li>
                        <li>
                          <Link
                            className="dropdown-item"
                            to="/candidates/editEducation"
                          >
                            Education
                          </Link>
                        </li>
                      </ul>
                    </li> */}

                    <li class="nav-item dropdown">
                      <Link
                        className="nav-link dropdown-toggle"
                        to=""
                        role="button"
                        data-bs-toggle="dropdown"
                        aria-expanded="false"
                      >
                        Employers <span class="caret"></span>
                      </Link>
                      <ul class="dropdown-menu">
                        <li>
                          <Link className="dropdown-item" to="/why-lgbti-job">
                            Why LGBTI jobs?
                          </Link>
                        </li>
                        <li>
                          <Link className="dropdown-item" to="/services">
                            Services
                          </Link>
                        </li>
                      </ul>
                    </li>

                    {/* <li class="nav-item dropdown">
                      <Link
                        className="nav-link dropdown-toggle"
                        href="#"
                        role="button"
                        data-bs-toggle="dropdown"
                        aria-expanded="false"
                      >
                        ENDORSED COMPANIES <span class="caret"></span>
                      </Link>
                      <ul className="dropdown-menu">
                        <li>
                          <Link className="dropdown-item" to="">
                            Maddocks
                          </Link>
                        </li>

                        <li>
                          <Link className="dropdown-item" to="">
                            Swinburne University
                          </Link>
                        </li>
                      </ul>
                    </li> */}

                    <li>
                      <Link to="/contact">
                      CONTACT US
                      </Link>
                    </li>
                    {/* <li className="nav-item dropdown">
                      <Link
                        className="nav-link dropdown-toggle"
                        href="#"
                        role="button"
                        data-bs-toggle="dropdown"
                        aria-expanded="false"
                      >
                        CONTACT US <span class="caret"></span>
                      </Link>
                      <ul class="dropdown-menu">
                        <li>
                          <Link className="dropdown-item" to="/aboutus">
                            About us
                          </Link>
                        </li>
                        <li>
                          <Link className="dropdown-item" to="/contact">
                            Contact us
                          </Link>
                        </li>
                      </ul>
                    </li> */}
                  </ul>
                  <hr />

                  <ul className="sub-line">
                    <li>
                      <Link to="/contact">Contact us</Link>
                    </li>
                    <li>
                      <Link to="/privacy-policy">Privacy Policy</Link>
                    </li>
                    <li>
                      <Link to={faceboookLink} target="_blank">
                        <i class="fa  fa-facebook-official"></i>
                      </Link>
                    </li>

                    <li>
                      <Link to={twitterLink} target="_blank">
                        <i class="fa fa-twitter-square"></i>
                      </Link>
                    </li>
                  </ul>
                </div>
                <div className="col-md-4 text-right ">
                  <img src="./logo.png" alt="" className="" />
                </div>
              </div>
            </div>
          </footer>
        </>
      ) : (
        <>
          <footer>
            <div className="container">
              <div className="row">
                <div className="col-md-8">
                  <ul className="nav footer-nav d-block">
                    <li>
                      <Link
                        to="/jobseeker-registration-information"
                        style={{ fontSize: "17px" }}
                      >
                        JOB SEEKERS
                      </Link>
                    </li>

                    <div className="FooterListLinks " onClick={toggle1}>
                      <div className="footerEachHeader">
                        <h3>EMPLOYERS </h3>
                        <Link className="footerPlusLink" onClick={toggle1}>
                          {isJobseekerLinksVisible ? (
                            <i className="fa-solid fa-circle-minus"></i>
                          ) : (
                            <i className="fa-solid fa-circle-plus"></i>
                          )}
                        </Link>
                      </div>

                      <ul
                        style={{
                          display: isJobseekerLinksVisible ? "block" : "none",
                        }}
                      >
                        <li>
                          <Link to="/why-lgbti-job" target="_blank">
                            Why LGBTI jobs?
                          </Link>
                        </li>
                        <li>
                          <Link to=" /services">Services</Link>
                        </li>
                      </ul>
                    </div>

                    {/* <div className="FooterListLinks" onClick={toggle2}>
                      <div className="footersubLinks">
                        <h3>ENDORSED COMPANIES</h3>
                        <Link className="footerAddicon">
                          {isAboutusLinksVisible ? (
                            <i className="fa-solid fa-circle-minus"></i>
                          ) : (
                            <i className="fa-solid fa-circle-plus"></i>
                          )}
                        </Link>
                      </div>

                      <ul
                        style={{
                          display: isAboutusLinksVisible ? "block" : "none",
                        }}
                      >
                        <li>
                          <Link className="dropdown-item" to="">
                            Maddocks
                          </Link>
                        </li>

                        <li>
                          <Link className="dropdown-item" to="">
                            Swinburne University
                          </Link>
                        </li>
                      </ul>
                    </div> */}
                    <li>
                      <Link
                        to="/contact"
                        style={{ fontSize: "17px" }}
                      >
                        CONTACT
                      </Link>
                    </li>

                    <div className="FooterLinks" onClick={toggle3}>
                      <div className="footerEachHeader">
                        {/* <h3>CONTACT US</h3> */}
                        {/* <Link className="footerPlusLink">
                          {isQuickLinksVisible ? (
                            <i className="fa-solid fa-circle-minus"></i>
                          ) : (
                            <i className="fa-solid fa-circle-plus"></i>
                          )}
                        </Link> */}
                      </div>

                      <ul
                        style={{
                          display: isQuickLinksVisible ? "block" : "none",
                        }}
                      >
                        {/* <li>
                          {" "}
                          <Link to="/aboutus" className="">
                            About us
                          </Link>
                        </li> */}
                        <li>
                          {" "}
                          <Link to="/contact" className="">
                            CONTACT US
                          </Link>
                        </li>
                      </ul>
                    </div>
                  </ul>
                  <hr />

                  <ul className="sub-line">
                    <li>
                      <Link to="/contact">Contact us</Link>
                    </li>
                    <li>
                      <Link to="/privacy-policy">Privacy Policy</Link>
                    </li>
                    <li>
                      <Link to={faceboookLink} target="_blank">
                        <i class="fa  fa-facebook-official"></i>
                      </Link>
                    </li>

                    <li>
                      <Link to={twitterLink} target="_blank">
                        <i class="fa fa-twitter-square"></i>
                      </Link>
                    </li>
                  </ul>
                </div>
                <div className="col-md-4 text-right d-none">
                  <img src="./logo.png" alt="" />
                </div>
              </div>
            </div>
          </footer>
        </>
      )}
    </>
  );
};

export default Footer;
