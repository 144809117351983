import React, { useEffect, useState } from "react";
// import NewNavBar from "../../element/NewNavBar";
import NavBar from "../element/NavBar";
import Footer from "../element/NewFooter";
import JobCard from "../element/JobCard";
import axios from "axios";
import { useParams } from "react-router-dom";
import { Link, useNavigate } from "react-router-dom";
import LocationOnIcon from "@mui/icons-material/LocationOn";
import BaseApi from "../api/BaseApi";
import Cookies from "js-cookie";
import { useTranslation } from "react-i18next";
import NewJobCard from "../element/NewJobCard";
import HTMLReactParser from "html-react-parser";
import Header from "./Header";

const SearchJobPage = () => {
  const { slug } = useParams();
  // const initialKeyword = sessionStorage.getItem("keywordTitle") || "";
  let keyWord = sessionStorage.getItem("keywordTitle");

  const initialKeyword = keyWord || slug || "";

  const initialCategory = sessionStorage.getItem("catId") || "";
  const [filterItem, setFilterItem] = useState({
    keyword: initialKeyword,
    category_id: initialCategory,
    subcategory_id: "",
    location: "",
  });

  const navigate = useNavigate();

  const [searchData, setSearchData] = useState([]);
  const [categoryList, setCategoryList] = useState([]);
  const [categoryData, setCategoryData] = useState([]);
  const [searchButton, setSearchButton] = useState(false);
  const [loading, setLoading] = useState(false);
  const [subCatData, setSubCatData] = useState([]);
  const [companyDetails, setCompanyDetails] = useState([]);

  // console.log(initialCategory);
  const [t, i18n] = useTranslation("global");
  const [itemSearched, setItemSearched] = useState(false);
  let userType = Cookies.get("user_type");

  const getData = async () => {
    try {
      setLoading(true);
      let response = await axios.post(
        BaseApi + `/job/companyjoblisting/${slug}`,
        null,
        {
          headers: {
            "content-type": "application/json",
          },
        }
      );
      setCategoryList(response.data.response.category);
      setCategoryData(response.data.response.jobs);
      setCompanyDetails(response.data.response.userDetails);
      setLoading(false);
    } catch (error) {
      setLoading(false);
      console.log("Error fetching list of categories");
    }
  };
  const tokenKey = Cookies.get("tokenClient");
  let primaryColor = Cookies.get("primaryColor");
  let secondaryColor = Cookies.get("secondaryColor");
  const mapKey = Cookies.get("mapKey");

  const [hoverColor, setHoverColor] = useState(false);

  const handleMouseEnter = () => {
    setHoverColor(true);
  };

  const handleMouseLeave = () => {
    setHoverColor(false);
  };

  const [clearColor, setClearColor] = useState(false);

  const handleClearMouseEnter = () => {
    setClearColor(true);
  };

  const handleClearMouseLeave = () => {
    setClearColor(false);
  };

  const [hoverPrevColor, setHoverPrevColor] = useState(false);
  const [hoverNextColor, setHoverNextColor] = useState(false);

  const handlePrevEnter = () => {
    setHoverPrevColor(true);
    // console.log(hoverColor)
  };

  const handlePrevLeave = () => {
    setHoverPrevColor(false);
  };
  const handleNextEnter = () => {
    setHoverNextColor(true);
    // console.log(hoverColor)
  };

  const handleNextLeave = () => {
    setHoverNextColor(false);
  };

  useEffect(() => {
    // if(!tokenKey){
    //   navigate("/user/jobseekerlogin");
    // }else{
    getData();
    if (initialCategory) {
      fetchJobListingsByCategory(initialCategory);
    }
    // }
  }, [initialKeyword]);

  const handleChange = (e) => {
    e.preventDefault();
    const { name, value } = e.target;

    // Check if the category_id is being changed
    setFilterItem((prevFilter) => ({
      ...prevFilter,
      [name]: value,
    }));
    if (name === "category_id") {
      // Call the API with the selected category_id
      fetchJobListingsByCategory(value);
    }
    if (filterItem.category_id === "Any Category") {
      // Include the category_id in the API request
      setFilterItem({ ...filterItem, category_id: "" }); // Convert to the desired data type if needed
    }
    console.log(filterItem);
  };

  const handleClick = async (e) => {
    e.preventDefault();
    if (
      filterItem.keyword === "" &&
      filterItem.category_id === "" &&
      filterItem.location === ""
    ) {
      return;
    }
    sessionStorage.setItem("jobSearched", "1");
    setSearchButton(true);
    setLoading(true);
    try {
      const response = await axios.post(BaseApi + "/job/listing", filterItem);
      setSearchData(response.data.response.jobs);
      setLoading(false);
      console.log("Search filter data sent successfully");
    } catch (error) {
      setLoading(false);
      console.log("Couldn't send the search filter data!");
    }
  };

  const fetchJobListingsByCategory = async (categoryId) => {
    // setLoading(true);
    try {
      const response = await axios.post(
        BaseApi + `/categories/getSubCategory/${categoryId}`
      );
      setSubCatData(response.data.response);
      setLoading(false);
      console.log(subCatData);
    } catch (error) {
      setLoading(false);
      console.log("Couldn't get sub category data at listing page");
    }
  };

  const handleReset = (e) => {
    e.preventDefault();
    setSearchButton(false);
    setSearchData([]);
    setSubCatData([]);
    setFilterItem({
      keyword: "",
      category_id: "",
      subcategory_id: "",
      location: "",
    });
  };

  useEffect(() => {
    setTimeout(() => {
      sessionStorage.clear();
    }, 7000);
    window.scrollTo(0, 0);
  }, []);

  // // Pagination state
  // const [currentPage, setCurrentPage] = useState(1);
  // const jobsPerPage = 12;

  // // Get current jobs to display based on pagination
  // const indexOfLastJob = currentPage * jobsPerPage;
  // const indexOfFirstJob = indexOfLastJob - jobsPerPage;
  // const currentJobs = searchButton
  //   ? searchData.slice(indexOfFirstJob, indexOfLastJob)
  //   : categoryData.slice(indexOfFirstJob, indexOfLastJob);

  // // Function to handle pagination page change
  // const handlePageChange = (pageNumber) => setCurrentPage(pageNumber);

  // Pagination state
  const [currentPage, setCurrentPage] = useState(1);
  const jobsPerPage = 12;

  // Get current jobs to display based on pagination
  const indexOfLastJob = currentPage * jobsPerPage;
  const indexOfFirstJob = indexOfLastJob - jobsPerPage;
  const currentJobs = searchButton
    ? searchData.slice(indexOfFirstJob, indexOfLastJob)
    : categoryData.slice(indexOfFirstJob, indexOfLastJob);

  // Function to handle pagination page change
  const handlePageChange = (pageNumber) => {
    setCurrentPage(pageNumber);
    window.scrollTo(0, 0); // Scroll to top when page changes
  };
  // Total number of jobs to calculate the number of pages
  const totalJobs = searchButton ? searchData.length : categoryData.length;
  const totalPages = Math.ceil(totalJobs / jobsPerPage);

  const [autocompleteService, setAutocompleteService] = useState(null);
  const [suggestions, setSuggestions] = useState([]);

  useEffect(() => {
    // Load Google Maps AutocompleteService after component mounts
    const script = document.createElement("script");
    script.src = `https://maps.googleapis.com/maps/api/js?key=${mapKey}&libraries=places`;
    script.onload = () => {
      setAutocompleteService(
        new window.google.maps.places.AutocompleteService()
      );
      console.log(autocompleteService);
    };
    document.body.appendChild(script);

    return () => {
      document.body.removeChild(script);
    };
  }, []);

  const handleLocationChange = (e) => {
    const { value } = e.target;
    setSuggestionTaken(false);
    if (value == "") {
      setSuggestionTaken(true);
    }

    setFilterItem((prevFilter) => ({
      ...prevFilter,
      location: value,
    }));

    if (autocompleteService) {
      // Call Google Maps Autocomplete API
      autocompleteService.getPlacePredictions(
        {
          input: value,
          types: ["(cities)"], // Restrict to cities if needed
        },
        (predictions, status) => {
          if (status === "OK" && predictions) {
            setSuggestions(
              predictions.map((prediction) => prediction.description)
            );
          } else {
            setSuggestions([]);
          }
        }
      );
    }
    if (filterItem.location === "") {
      setSuggestions([]);
    }
  };
  const [suggestionTaken, setSuggestionTaken] = useState(false);

  const handleSuggestionClick = (suggestion) => {
    // Update the input value with the clicked suggestion
    handleLocationChange({ target: { name: "location", value: suggestion } });

    setSuggestionTaken(true);
    // Clear the suggestions
    setSuggestions([]);
    console.log(filterItem);
  };

  return (
    <>
      {/* <NavBar /> */}
      <Header />
      {loading ? (
        <div className="loader-container"></div>
      ) : (
        <>
          <div className="container">
            <ol class="breadcumbs">
              <li>
                <Link to="" title="HOME">
                  HOME
                </Link>
              </li>

              <li>LGBTI Jobs</li>
            </ol>
          </div>
          <div className="sectionJobListing">
            <div className="container">
              <div className="row">
                <div className="col-md-8 col-sm-12 ">
                  <div className="row">
                    <div className="col-md-12">
                      <div className="mb-3">
                      <img src={companyDetails.profile_image} alt="" />

                        {/* <h2 className="mb-2">{companyDetails.company_name}</h2> */}
                        {/* <p>
                          {companyDetails.company_about &&
                            HTMLReactParser(companyDetails.company_about)}
                        </p> */}
                      </div>

                      <div className="record-count">
                        <p>
                          <strong>{totalJobs}</strong> record(s) have been
                          found.
                        </p>
                      </div>
                    </div>
                  </div>
                  <div className="row">
                    {searchButton ? (
                      currentJobs.length > 0 ? (
                        currentJobs.map((i) => (
                          <>
                            <div className="col-md-12 col-sm-12">
                              <NewJobCard
                                title={i.title}
                                min_salary={i.min_salary}
                                max_salary={i.max_salary}
                                min_exp={i.min_exp}
                                created={i.created}
                                logo={i.logo}
                                company_name={i.company_name}
                                work_type={i.work_type}
                                slug={i.slug}
                                cat_slug={i.cat_slug}
                                job_city={i.job_city}
                                desc={i.description}
                              />
                            </div>
                          </>
                        ))
                      ) : (
                        <div className="col-12">
                          <h3 className="text-center">
                            {t("searchJobPage.noJobsTxt1")}{" "}
                          </h3>
                          <h6 className="text-muted text-center mb-5 mt-3">
                            {t("searchJobPage.noJobsTxt2")}
                          </h6>
                        </div>
                      )
                    ) : categoryData.length > 0 ? (
                      currentJobs.map((i) => (
                        <>
                          <div className="col-md-12 col-sm-12">
                            <NewJobCard
                              title={i.title}
                              min_salary={i.min_salary}
                              max_salary={i.max_salary}
                              min_exp={i.min_exp}
                              created={i.created}
                              logo={i.logo}
                              company_name={i.company_name}
                              work_type={i.work_type}
                              slug={i.slug}
                              cat_slug={i.cat_slug}
                              job_city={i.job_city}
                              desc={i.description}
                            />
                          </div>
                        </>
                      ))
                    ) : (
                      <div className="col-12">
                        <h3 className="text-center">
                          {t("searchJobPage.noJobsTxt1")}{" "}
                        </h3>
                        <h6 className="text-muted text-center mb-5 mt-3">
                          {t("searchJobPage.noJobsTxt2")}{" "}
                        </h6>
                      </div>
                    )}
                  </div>
                </div>
                <div className="col-md-4 col-sm-12">
                  <div className="promo-block">
                    <h2>Contact Information</h2>
                    <p>{companyDetails.address}</p>
                    <p>
                    <i class="fa fa-phone" aria-hidden="true"></i> {companyDetails.contact}
                    </p>
                    <p>
                    <i class="fa fa-envelope" aria-hidden="true"></i>{" "}
                      <Link to="" style={{ color: "#422778" }}>
                        {companyDetails.email_address}
                      </Link>
                    </p>
                    {companyDetails.company_about?.length > 20 && (
                      <p>
                        <Link
                          to={`/companyprofile/${companyDetails.slug}`}
                          className="btn btn-primary"
                          // target="_blank"
                        >
                          <i class="fa fa-globe" aria-hidden="true"></i> View Micro Site
                        </Link>
                      </p>
                    )}
                  </div>
                  <div className="promo-block">
                    <h2>Find your next job faster than ever!</h2>
                    <ul>
                      <li>
                        <img
                          src="/Images/checked_icon.png"
                          alt="icon"
                          className="img-fluid"
                        />
                        FREE online registration
                      </li>
                      <li>
                        <img
                          src="/Images/checked_icon.png"
                          alt="icon"
                          className="img-fluid"
                        />
                        Daily job alerts
                      </li>
                      <li>
                        <img
                          src="/Images/checked_icon.png"
                          alt="icon"
                          className="img-fluid"
                        />
                        Online profile &amp; CV (searchable by clients)
                      </li>
                      <li>
                        <img
                          src="/Images/checked_icon.png"
                          alt="icon"
                          className="img-fluid"
                        />
                        Large client &amp; agency database
                      </li>
                    </ul>
                    <p>
                      {!tokenKey && (
                        <Link
                          to="/user/register/jobseeker"
                          className="btn btn-primary "
                        >
                          Register now
                        </Link>
                      )}
                    </p>
                  </div>

                  <div className="promo-block">
                    <h2>Maximize your chances!</h2>
                    <p className="text-customize">
                      Ensure that your online CV is up to date as employers are
                      always searching our online CV database of candidates.
                    </p>

                    {tokenKey !== "" && userType === "candidate" ? (
                      <p>
                        <Link
                          to="/candidates/myaccount"
                          className="btn btn-primary "
                        >
                          Modify your profile
                        </Link>
                      </p>
                    ) : (
                      <p>
                        <Link
                          to="/user/jobseekerlogin"
                          className="btn btn-primary "
                        >
                          Modify your profile
                        </Link>
                      </p>
                    )}
                  </div>

                  <div className="promo-block">
                    <h2>Need assistance?</h2>
                    <p className="text-customize">
                      A member of our staff is ready to help you with any
                      questions you may have.
                    </p>
                    <p>
                      <Link to="/contact" className="btn btn-primary">
                        Contact us for a fast response
                      </Link>
                    </p>
                  </div>
                </div>
              </div>
            </div>
          </div>

          <Footer />
        </>
      )}
    </>
  );
};

export default SearchJobPage;
