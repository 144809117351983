import React, { useEffect, useState } from "react";
import NavBar from "../element/NavBar";
import Footer from "../element/NewFooter";
import { Link } from "react-router-dom";
import axios from "axios";
import BaseApi from "../api/BaseApi";
import HTMLReactParser from "html-react-parser";
import Cookies from "js-cookie";
import { useTranslation } from "react-i18next";
import Header from "../element/Header";

const AboutUs = () => {
  const [aboutUs, setAboutUs] = useState([]);
  const [t, i18n] = useTranslation("global");
  const currentLanguage = Cookies.get("selectedLanguage") || "en";

  const getData = async () => {
    try {
      const response = await axios.post(BaseApi + `/page/about_us`, {
        language: currentLanguage,
      });
      setAboutUs(response.data.response);
      const metaTitle = document.querySelector('meta[name="title"]');
        console.log(metaTitle.content, "metaTitle");
        if (metaTitle) {
          metaTitle.content = response.data.response.meta_tile;
        }
        // console.log("hi");
        const metaDescription = document.querySelector('meta[name="description"]');
        console.log(metaDescription.content, "metaDescription");
        if (metaDescription) {
          metaDescription.content = response.data.response.meta_description;
        }
  
        const metaKeywords = document.querySelector('meta[name="keywords"]');
        if (metaKeywords) {
          metaKeywords.content = response.data.response.meta_keyword;
        }
    } catch (error) {
      console.log("Cannot get data about us!");
    }
  };

  let primaryColor = Cookies.get("primaryColor");
  let secondaryColor = Cookies.get("secondaryColor");

  useEffect(() => {
    getData();
    // getConstantData();
    window.scrollTo(0, 0);
  }, []);

  return (
    <>
      <div className="aboutUs">
        {/* <NavBar /> */}
        <Header />

        <div className="newAboutus text-center">
          {/* <h1>
            {aboutUs.page_title ? HTMLReactParser(aboutUs.page_title) : ""}
          </h1>
          <h6 className="text-muted fw-normal">
            {" "}
            <Link to="/" style={{ color: "grey" }}>
              {t("navHeaders.home")}
            </Link>{" "}
            /{aboutUs.page_title}
          </h6> */}
        </div>

        <div className="content-general">
          <div className="container">
            <div className="col-sm-12">
              <img
                src="/Images/globe-awards-winner.png"
                alt="global-awards-winner"
                className="winning-award"
              />
              <h1>Leadership team</h1>
              <h2>
                Helping organisations build an inclusive workplace where
                diversity is recognised and respected.
              </h2>
              <hr />
              <h2>
                <img
                  src="/Images/pic-shulah.jpg"
                  alt="our-images-pic"
                  className="img-circle"
                />
                Shulah Orloff (She/Her) - Lead Consultant
              </h2>
              <p>
                Shulah has worked towards achieving diversity and inclusivity
                throughout her life. Growing up as a trans woman in the Jewish
                community taught her the value of community, as well as the need
                to be open and inclusive to establish an environment that
                everyone can prosper in. Volunteering in community groups since
                her teenage years, Shulah has a passion for helping develop
                others' understanding of the importance of diversity.
              </p>
              <p className="my-3">
                It is through her own experiences navigating the workforce,
                initially as a closeted and now proudly out trans woman, that
                inspires Shulah to help inspire others in the workforce, with a
                commitment to develop a welcoming space that brings everyone
                equally to the table. As the modern workplace is constantly
                adapting, it is Shulah’s goal to make sure no one is left
                behind.
              </p>

              <h3>
                <i class="fa fa-linkedin-square"></i>{" "}
                <Link to="https://www.linkedin.com/in/shulah-orloff-1901a219a/" target="_blank">
                  LinkedIn
                </Link>
              </h3>
              <hr />

              <h2>
                <img
                  src="/Images/pic-jared.jpg"
                  alt="our-images-pic"
                  className="img-circle"
                />
                Jared Telling - Digital Services Manager
              </h2>
              <p>
              Jared's online experience started back in the days of dialup modems and he hasn't looked back since. During the dotcom boom he was Webmaster for one of the first high profile e-commerce websites theLounge.com.au. In 2001 he moved to London where he worked for one of the UK's leading online digital agencies working with companies such as Disneyland Paris, Dixons, Starbucks, BT (British Telecom) and Marks & Spencer where he eventually became part of the in-house web team working solely on their e-commerce website. In 2004 He then moved to France and setup his own web agency servicing clients locally and also in the UK and Australia.
              </p>
              <p className="my-3">
              He's been involved in web based projects of all scales and sizes, with extensive experience in e-commerce, online recruitment, content management systems, online marketing and digital design. He brings his vast web experience to LGBTI JOBS and he's extremely motivated to assist in building an equal working environment.
              </p>

              <h3>
                <i class="fa fa-linkedin-square"></i>{" "}
                <Link to="https://www.linkedin.com/in/jared-telling-666286" target="_blank">
                  LinkedIn
                </Link>
              </h3>
              <hr />

              <h2>
                <img
                  src="/Images/pic-nicolas.jpg"
                  alt="our-images-pic"
                  className="img-circle"
                />
                Nicolas Le Berre - Senior Web Developer
              </h2>
              <p>
              After graduating with a Degree in Business, Nicolas made the switch to Web Development where he found his true calling after completing a 2nd degree in Computer Programming. Specialised in all things dynamic, he is particularly focused on database integration, Javascript and PHP.
              </p>
              <p className="my-3">
              Together with Jared he is co-director of a web agency and manages the France office. He comes from a web agency background working on clients of all sizes in Paris and Brittany.
              </p>
              <p className="my-3">
              Over the course of the last 15 years Nicolas has been involved in developing bespoke e- commerce web sites, job boards and content management systems. Not only does he bring the technical know how but his marketing and business experience greatly assist in ensuring that web sites not only work but deliver the desired business goals and returns on investment.
              </p>

              <h3>
                {/* <i class="fa fa-linkedin-square"></i>{" "}
                <Link to="" target="_blank">
                  LinkedIn
                </Link> */}
              </h3>
            </div>
          </div>
        </div>
        
       
        <Footer />
      </div>
    </>
  );
};

export default AboutUs;
