import React, { useEffect, useState } from "react";
import Footer from "../element/NewFooter";
import { Link, useNavigate } from "react-router-dom";
import NavBar from "../element/NavBar";
import axios from "axios";
import BaseApi from "../api/BaseApi";
import Swal from "sweetalert2";
import ReCAPTCHA from "react-google-recaptcha";
import Cookies from "js-cookie";
import HTMLReactParser from "html-react-parser";
import { useTranslation } from "react-i18next";
import Header from "../element/Header";

const HowItWorks = () => {
  const [formData, setFormData] = useState({
    name: "",
    email: "",
    subject: "",
    message: "",
  });
  const [t, i18n] = useTranslation("global");
  const [errors, setErrors] = useState({
    name: "",
    email: "",
    subject: "",
    message: "",
  });
  const [isCaptchaVerified, setIsCaptchaVerified] = useState(false);
  const recaptchaLanguage = Cookies.get("selectedLanguage");
  const navigate = useNavigate();
  const userType = Cookies.get("user_type");
  const tokenKey = Cookies.get("tokenClient");
  const handleChange = (e) => {
    const { name, value } = e.target;

    setFormData((prevData) => ({
      ...prevData,
      [name]: value,
    }));
    setErrors((prev) => ({
      ...prev,
      [name]: "",
    }));
  };

  let primaryColor = Cookies.get("primaryColor");
  let secondaryColor = Cookies.get("secondaryColor");
  let captchaKey = Cookies.get("captchaKey");

  const [hoverColor, setHoverColor] = useState(false);

  const handleMouseEnter = () => {
    setHoverColor(true);
  };

  const handleMouseLeave = () => {
    setHoverColor(false);
  };

  const [pageContent, setPageContent] = useState([]);
  const [loading, setLoading] = useState(false);

  const getData = async () => {
    try {
      setLoading(true);
      const response = await axios.post(
        BaseApi + `/page/staticpage/how-it-works`,{language: currentLanguage}
      );
      setLoading(false);
      setPageContent(response.data.response);
      const metaTitle = document.querySelector('meta[name="title"]');
        console.log(metaTitle.content, "metaTitle");
        if (metaTitle) {
          metaTitle.content = response.data.response.meta_tile;
        }
        // console.log("hi");
        const metaDescription = document.querySelector('meta[name="description"]');
        console.log(metaDescription.content, "metaDescription");
        if (metaDescription) {
          metaDescription.content = response.data.response.meta_description;
        }
  
        const metaKeywords = document.querySelector('meta[name="keywords"]');
        if (metaKeywords) {
          metaKeywords.content = response.data.response.meta_keyword;
        }
    } catch (error) {
      setLoading(false);
      console.log("Cannot get data of how it works page");
    }
  };

  useEffect(() => {
    getData();
  }, []);

  // useEffect(() => {
  //   if (
  //     (!tokenKey && userType != "recruiter") ||
  //     (!tokenKey && userType != "candidate")
  //   ) {
  //     navigate("/user/jobseekerlogin");
  //   } else {
  //     getData();
      
  //   }
  // }, []);

  const [recaptchaKey, setRecaptchaKey] = useState(captchaKey);
  const [recaptchaVisible, setRecaptchaVisible] = useState(true);
  const currentLanguage = Cookies.get("selectedLanguage") || "en";

  const handleRecaptchaReset = () => {
    setRecaptchaVisible(false);
    setRecaptchaKey(generateRecaptchaKey());
    setTimeout(() => {
      setRecaptchaVisible(true);
    }, 0); // Using setTimeout to unmount and then mount the ReCAPTCHA
  };

  const generateRecaptchaKey = () => {
    return Math.random().toString(36).substring(2, 15);
  };

  const handleClick = async (e) => {
    e.preventDefault();
    try {
      const newErrors = {};

      if (formData.name === "") {
        newErrors.name = t("messageForm.nameRequired");
      }
      if (formData.email === "") {
        newErrors.email = t("messageForm.emailRequired");
      } else if (!isValidEmail(formData.email)) {
        newErrors.email = t("messageForm.invalidEmail");
      }
      if (formData.subject === "") {
        newErrors.subject = t("messageForm.subjectRequired");
      }
      if (formData.message === "") {
        newErrors.message = t("messageForm.messageRequired");
      }
      if (!isCaptchaVerified) {
        newErrors.captcha = t("messageForm.captchaRequired");
      }
      setErrors(newErrors);

      // Function to validate email format
      function isValidEmail(email) {
        // Use a regular expression to validate email format
        const emailPattern = /^[a-zA-Z0-9._-]+@[a-zA-Z0-9.-]+\.[a-zA-Z]{2,4}$/;
        return emailPattern.test(email);
      }

      if (Object.keys(newErrors).length === 0) {
        if (isCaptchaVerified) {
          const response = await axios.post(
            BaseApi + "/page/contact-us",
            formData
          );
          // Reset the reCAPTCHA
          handleRecaptchaReset();
          if (response.data.status === 200) {
            Swal.fire({
              title: t("messageForm.messageSuccessTitle"),
              icon: "success",
              confirmButtonText: t("messageForm.close"),
            });
            setFormData({
              ...formData,
              name: "",
              email: "",
              subject: "",
              message: "",
            });
          } else if (response.data.status === 500) {
            Swal.fire({
              title: response.data.message,
              icon: "error",
              confirmButtonText: t("messageForm.close"),
            });
          } else {
            Swal.fire({
              title: response.data.message,
              icon: "error",
              confirmButtonText: t("messageForm.close"),
            });
          }
        }
      }
    } catch (error) {
      Swal.fire({
        title: t("messageForm.messageFailedTitle"),
        text: t("messageForm.messageFailedTxt"),
        icon: "error",
        confirmButtonText: t("messageForm.close"),
      });
    }
    console.log(formData);
  };
  useEffect(() => {
    window.scrollTo(0, 0);
  }, []);
  return (
    <>
      {/* <NavBar /> */}
      <Header />
      <div className="privacyPolicy">
        <div className="text-center PPSection1">
          <h1 className="">{pageContent.page_title}</h1>
          <h6 className="text-muted fw-normal">
            {" "}
            <Link to="/" style={{ color: "grey" }}>
              {t("navHeaders.home")}
            </Link>{" "}
            /{pageContent.page_title}
          </h6>
        </div>
        <div className="container">
          <div className="row">
            <div className="col-md-6">
              <div className="upperPart">
                <p>
                  {pageContent.page_description &&
                    HTMLReactParser(pageContent.page_description)}
                </p>
              </div>
            </div>
            <div className="col-md-6">
              <div className="card">
                <div className="card-body">
                  <h2 className="text-center pb-4 pt-2">
                    {t("messageForm.sendusa")}
                    <span className="textGradient">
                      {" "}
                      <span className="SubHaddingTxt">
                        {" "}
                        {t("messageForm.message")}
                      </span>
                    </span>
                  </h2>
                  <form>
                    <div className="mb-4">
                      <input
                        type="text"
                        className={`form-control ${
                          errors.name && "input-error"
                        }`}
                        id="exampleInputEmail1"
                        aria-describedby="emailHelp"
                        name="name"
                        value={formData.name}
                        placeholder={t("messageForm.namePlaceholder")}
                        onChange={handleChange}
                      />
                      {errors.name && (
                        <div className="text-danger">{errors.name}</div>
                      )}
                    </div>
                    <div className="mb-4">
                      <input
                        type="email"
                        className={`form-control ${
                          errors.email && "input-error"
                        }`}
                        id="exampleInputEmail1"
                        aria-describedby="emailHelp"
                        name="email"
                        value={formData.email}
                        placeholder={t("messageForm.emailPlaceholder")}
                        onChange={handleChange}
                      />
                      {errors.email && (
                        <div className="text-danger">{errors.email}</div>
                      )}
                    </div>
                    <div className="mb-4">
                      <select
                        className={`form-select ${
                          errors.subject && "input-error"
                        }`}
                        aria-label="Default select example"
                        value={formData.subject}
                        name="subject"
                        onChange={handleChange}
                      >
                        <option>{t("messageForm.selectPlaceholder")}</option>
                        <option value="1">
                          {t("messageForm.messageFormSelectOption1")}
                        </option>
                        <option value="2">
                          {t("messageForm.messageFormSelectOption2")}
                        </option>
                        <option value="3">
                          {t("messageForm.messageFormSelectOption3")}
                        </option>
                        <option value="3">
                          {t("messageForm.messageFormSelectOption4")}
                        </option>
                        <option value="3">
                          {t("messageForm.messageFormSelectOption5")}
                        </option>
                      </select>
                      {errors.subject && (
                        <div className="text-danger">{errors.subject}</div>
                      )}
                    </div>
                    <div className="mb-4">
                      <textarea
                        className={`form-control ${
                          errors.message && "input-error"
                        }`}
                        id="exampleFormControlTextarea1"
                        rows="5"
                        name="message"
                        value={formData.message}
                        placeholder={t("messageForm.descPlaceholder")}
                        onChange={handleChange}
                      ></textarea>
                      {errors.message && (
                        <div className="text-danger">{errors.message}</div>
                      )}
                    </div>
                    <div className="reCaptchaLogin mb-4">
                      <ReCAPTCHA
                        key={recaptchaKey}
                        sitekey={captchaKey}
                        hl={recaptchaLanguage}
                        onChange={(value) => setIsCaptchaVerified(value)}
                      />
                      {errors.captcha && (
                        <div className="text-danger">{errors.captcha}</div>
                      )}
                    </div>
                    <button
                      type="submit"
                      className="btn w-100"
                      onClick={handleClick}
                      style={{
                        backgroundColor: `${
                          secondaryColor &&
                          (hoverColor ? secondaryColor : primaryColor)
                        }`,
                      }}
                      onMouseEnter={handleMouseEnter}
                      onMouseLeave={handleMouseLeave}
                    >
                      {/* SEND MESSAGE */}
                      {t("messageForm.sendMessageButton")}
                    </button>
                  </form>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
      <Footer />
    </>
  );
};

export default HowItWorks;
